import React, { useMemo } from "react";

import { ItemState } from "@appfire/poker-core";
import Lozenge from "@atlaskit/lozenge";

import { Voting } from "../../../types";
interface AverageScoreProps {
  activeItemState: ItemState;
  activeItemVotes?: Voting;
}

export function AverageScore({ activeItemVotes, activeItemState }: Readonly<AverageScoreProps>) {
  const averageLabel = useMemo(() => {
    if (activeItemVotes?.votes && activeItemState === ItemState.FINAL_ESTIMATION) {
      let sum = 0;
      let count = 0;

      for (const vote of Object.values(activeItemVotes.votes)) {
        if (vote === "?" || vote === "coffee") continue;

        const numericVote = Number(vote);
        if (!isNaN(numericVote)) {
          sum += numericVote;
          count++;
        }
      }

      if (count > 0) {
        const average = sum / count;
        return average % 1 === 0 ? average : average.toFixed(2);
      }
    }
    return "N/A";
  }, [activeItemVotes?.votes, activeItemState]);

  return (
    <Lozenge isBold={true} appearance="new">
      {`Average score ${averageLabel}`}
    </Lozenge>
  );
}
